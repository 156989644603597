import { css } from 'styled-components';

export const cta = css`
	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 4px;
	border-radius: 5px;
	color: var(--color-text-onPrimary);
	box-shadow: 0px 2px 4px 0px var(--color-text-onSurface);
	background: var(--color-gradient-default);

	&:hover {
		background: var(--color-gradient-hover);
		transition-property: all;
		transition-duration: var(--transition-duration);
		transition-timing-function: var(--transition-timingFunction);
		transition-delay: 0s;
	}

	&:disabled {
		background: var(--color-gradient-disabled);
	}
`;
